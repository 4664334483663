@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100..900&display=swap');

html, body {
    overflow-x: hidden;
}

body {
    position: relative
}

body.overlay-open {
    overflow: hidden;
}

.lp_container {
    background-color: #FFFFFF;
    min-height: 72px;
    width: 100vw;
    z-index: 10000;
    /*margin-top: 20px;*/
    position: sticky;
    top: 0;
}

.mobile-lp_container {
    display: none;
}


.flex_h {
    display: flex;
    justify-content: center;
    align-items: center;
}

.nav1 {
    height: 52px;
    /* border: 2px solid blue; */
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-inline: 33.49px 107px;
}

.lp_logo {
    width: 227.286px;
    height: 30px;
}

.nav1 ul {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-grow: 1;
    padding-inline: 10%;
    gap: 5%;
}

ul {
    list-style-type: none;
}

.link {
    font-family: 'Inter', sans-serif;
    font-size: 15px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    color: #121212;
    transition: color 0.3s; /* Add smooth transition for color change */
    cursor: pointer;
    text-decoration: none;
}

.link:hover {
    color: #62A4CC;
    font-weight: 600; /* Change font weight on hover */
}

.nav-book-button {
    display: flex;
    width: 126px;
    padding: 8px 12px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    border-radius: 5px;
    background: #082856;
    color: #FFF;
    font-family: 'Inter', sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    cursor: pointer;
}


.head_right {
    gap: 14px;
}

.lp_menu_icon {
    display: none;
    width: 22px;
    height: 22px;
}

.contact-us-center {
    width: 100%;
    height: 100%;
    display: flex;
    position: fixed;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    z-index: 100;
    background: rgba(0, 0, 0, 0.5);
}

.contact-us-overlay {
    display: block;
    /* position: fixed; */
    padding: 22px 40px 32px 40px;
    top: 0;
    left: 0;
    width: 45%;
    height: 75%;
    background: #FFF; /* Semi-transparent white background */
    z-index: 999; /* Ensure it's above other content */
    justify-content: flex-start;
    align-items: flex-start;
    border-radius: 10px;
    border: 0.5px solid rgba(108, 117, 125, 0.50);
    gap: 24px;
    overflow-y: auto;
}

.contact-us-overlay.active {
    display: flex;
}

.contact-us-overlay-heading {
    color: #000;
    font-family: 'Inter', sans-serif;
    font-size: 24px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
}

.contact-us-overlay-subheading {
    color: #000;
    font-family: 'Inter', sans-serif;
    font-size: 18px;
    font-style: normal;
    font-weight: 300;
    line-height: normal;
    padding-top: 24px;
}

.contact-us-overlay-label {
    color: #000;
    font-family: 'Inter', sans-serif;
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    padding-top: 24px;
}

.contact-us-overlay-textfield {
    width: 85%;
    height: 50px;
    border-radius: 10px;
    border: 0.5px solid #D7D7D7;
    background: #FFF;
    padding-left: 10px;
    font-size: 16px;
    margin-top: 8px;
}

.contact-us-overlay-row {
    width: 87.5%;
    height: 50px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    gap: 14px;
}

.contact-us-overlay-row-1 {
    width: 87.5%;
    height: 50px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    gap: 14px;
    margin-top: 24px;
}

.contact-us-overlay-row-2 {
    width: 100%;
    height: 50px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    gap: 8px;
    /* margin-top: 24px; */
}

.contact-us-overlay-row-3 {
    width: 100%;
    /* height: 50px; */
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    gap: 8px;
    /* margin-top: 24px; */
}

.contact-us-overlay-textfield-1 {
    width: 90%;
    height: 50px;
    border-radius: 10px;
    border: 0.5px solid #D7D7D7;
    background: #FFF;
    padding-left: 10px;
    font-size: 16px;
    margin-top: 14px;
}

.contact-us-overlay-textfield-2 {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 15%;
    height: 50px;
    border-radius: 10px;
    border: 0.5px solid #D7D7D7;
    background: #FFF;
    /* padding-left: 10px; */
    font-size: 16px;
    margin-top: 14px;
}

.contact-us-country-code {
    color: #000;
    font-family: 'Inter', sans-serif;
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
}


.contact-us-overlay-content {
    display: flex;
    flex-direction: column;
    padding: 22px 39px 32px 39px;
    align-items: flex-end;
    border-radius: 10px;
    border: 0.5px solid rgba(108, 117, 125, 0.50);
    background: #FFF;
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.03), 0px 4px 4px 0px rgba(0, 0, 0, 0.03) inset;
}

.contact-us-overlay-note {
    color: #FD7981;
    font-family: 'Inter', sans-serif;
    font-size: 13px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    margin-top: 30px;
}

.contact-us-overlay-terms {
    color: #000;
    font-family: 'Inter', sans-serif;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

.contact-us-overlay-submit {
    display: flex;
    width: 315px;
    height: 50px;
    /* padding: 9px 15px; */
    justify-content: center;
    align-items: center;
    gap: 10px;
    border-radius: 5px;
    background: #082856;
    color: #FFF;
    text-align: center;
    font-family: 'Inter', sans-serif;
    font-size: 15px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    margin-top: 24px;
    cursor: pointer;
}

.contact-us-overlay-icon {
    display: block;
    width: 24px;
    height: 24px;
}

.contact-us-overlay-textfield.empty {
    border: 1px solid red;
}

@media screen and (max-width: 430px) {

    .lp_container {
        display: none;
    }

    .mobile-lp_container {
        display: block;
        background-color: var(--default-white);
        min-height: 50px;
        width: 100vw;
    }

    .nav1 {
        width: 85vw;
        height: 52px;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
    }

    .lp_logo {
        width: 136px;
        height: 18px;
    }

    .nav-book-button {
        display: flex;
        width: 104px;
        padding: 8px 12px;
        justify-content: center;
        align-items: center;
        gap: 10px;
        border-radius: 5px;
        background: #082856;
        color: #FFF;
        font-family: 'Inter', sans-serif;
        font-size: 13px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
        cursor: pointer;
    }

    .lp_menu_icon {
        display: block;
        width: 22px;
        height: 22px;
    }

    .overlay {
        display: block;
        position: fixed;
        top: 50;
        left: 0;
        width: 100%;
        height: 100%;
        background: rgba(0, 0, 0, 0.5); /* Semi-transparent black background */
        z-index: 999; /* Ensure it's above other content */
        transition: transform 0.3s ease;
    }

    .navbar-items {
        display: flex;
        width: 100%;
        height: 100%;
        padding: 30px 28px 30px 28px;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
        background: #FFF;
        gap: 34px;
    }

    .divider {
        display: flex;
        width: 80%;
        height: 1.5px;
        background: #EAEAE9;
    }

    .menu-items {
        color: #000;
        text-align: center;
        font-family: 'Inter', sans-serif;
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        text-decoration: none;
    }

    .contact-us {
        display: flex;
        width: 70%;
        padding: 6.689px 0px 6.442px 0px;
        justify-content: center;
        align-items: center;
        border-radius: 4.459px;
        border: 1px solid #082856;
        color: #082856;
        text-align: center;
        font-family: 'Inter', sans-serif;
        font-size: 14px;
        font-style: normal;
        font-weight: 600;
        line-height: 26.754px;
        margin-left: 5%;
        margin-top: 15px;
    }

    .contact-us-overlay {
        display: block;
        /* position: fixed; */
        padding: 22px 40px 32px 40px;
        top: 0;
        left: 0;
        width: 75%;
        height: 75%;
        background: #FFF; /* Semi-transparent white background */
        z-index: 999; /* Ensure it's above other content */
        justify-content: flex-start;
        align-items: flex-start;
        border-radius: 10px;
        border: 0.5px solid rgba(108, 117, 125, 0.50);
        gap: 24px;
        overflow-y: auto;
    }

    .contact-us-overlay.active {
        display: flex;
    }

    .contact-us-overlay-heading {
        color: #000;
        font-family: 'Inter', sans-serif;
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
    }

    .contact-us-overlay-subheading {
        color: #000;
        font-family: 'Inter', sans-serif;
        font-size: 13px;
        font-style: normal;
        font-weight: 300;
        line-height: normal;
        padding-top: 10px;
    }

    .contact-us-overlay-label {
        color: #000;
        font-family: 'Inter', sans-serif;
        font-size: 12px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        padding-top: 10px;
    }

    .contact-us-overlay-textfield-1 {
        width: 90%;
        height: 30px;
        border-radius: 10px;
        border: 0.5px solid #D7D7D7;
        background: #FFF;
        padding-left: 10px;
        font-size: 12px;
        margin-top: 6.5px;
    }

    .contact-us-overlay-textfield-2 {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 15%;
        height: 30px;
        border-radius: 10px;
        border: 0.5px solid #D7D7D7;
        background: #FFF;
        /* padding-left: 10px; */
        font-size: 12px;
        margin-top: 6.5px;
    }

    .contact-us-overlay-textfield {
        width: 85%;
        height: 30px;
        border-radius: 10px;
        border: 0.5px solid #D7D7D7;
        background: #FFF;
        padding-left: 10px;
        font-size: 12px;
        margin-top: 6.5px;
    }

    .contact-us-country-code {
        color: #000;
        font-family: 'Inter', sans-serif;
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
    }

    .contact-us-overlay-row {
        width: 87.5%;
        height: 50px;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: flex-start;
        gap: 14px;
    }

    .contact-us-overlay-row-1 {
        width: 87.5%;
        height: 50px;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: flex-start;
        gap: 14px;
        margin-top: 10px;
    }

}

.custom-translate-dropdown {
    position: relative;
    display: flex;
    padding-left: 24px;
    padding-top: 12px;
}

.custom-translate-dropdown select {
    padding: 8px 16px;
    font-size: 14px;
    color: #333;
    background-color: #f0f0f0;
    border: 1px solid #ccc;
    border-radius: 5px;
    cursor: pointer;
    transition: all 0.3s ease;
}

/* Hover and focus effects */
.custom-translate-dropdown select:hover,
.custom-translate-dropdown select:focus {
    background-color: #fff;
    border-color: #007bff;
    box-shadow: 0 0 5px rgba(0, 123, 255, 0.5);
    outline: none;
}

/* Dropdown arrow customization */
.custom-translate-dropdown select::-ms-expand {
    display: none; /* Hide default dropdown arrow in IE */
}

.custom-translate-dropdown select {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    padding-right: 24px;
    background-image: url("data:image/svg+xml;charset=UTF-8,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'><polygon points='0,0 10,10 20,0' fill='%23333'/></svg>");
    background-position: right 10px center;
    background-repeat: no-repeat;
    background-size: 12px;
}

/* Optional: Style the dropdown container */
.custom-translate-dropdown::before {
    position: absolute;
    top: 10px;
    left: 0;
    font-size: 11px;
    color: #666;
}

@media (max-width: 600px) {
    #google_translate_element {
        display: block;
        position: fixed;
        bottom: 10px;
        right: 10px;
        z-index: 9999;
    }
}

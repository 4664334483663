@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100..900&display=swap');

/* About Header */
.about-header-div{
    display: flex;
    width: 100%;
    padding: 40px 0px;
    flex-direction: column;
    align-items: center;
    gap: 17px;
}

.about-header-title{
    color: #082856;
    text-align: center;
    font-family: 'Inter', sans-serif;
    font-size: 40px;
    font-style: normal;
    font-weight: 600;
    line-height: 60px;
}

.about-header-subtitle{
    width: 46.25%;
    color: #082856;
    text-align: center;
    font-family: 'Inter', sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 27.2px; /* 170% */
}

@media screen and (max-width: 430px){

    .about-header-div{
        display: flex;
        width: 100%;
        padding: 40px 0px;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        gap: 17px;
    }

    .about-header-title{
        color: #082856;
        text-align: center;
        font-family: 'Inter', sans-serif;
        font-size: 32px;
        font-style: normal;
        font-weight: 600;
        line-height: 48px;
        width: 95%;
    }

    .about-header-subtitle{
        width: 87.5%;
        color: #082856;
        text-align: center;
        font-family: 'Inter', sans-serif;
        font-size: 15px;
        font-style: normal;
        font-weight: 400;
        line-height: 27.2px; /* 170% */
    }

}

/* Productivity Section */
.productivity-div{
    display: flex;
    width: 100%;
    padding: 40px 0px;
    flex-direction: column;
    align-items: center;
    gap: 17px;
}

.productivity-div-row{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
    width: 100%;
    margin-left: 150px;
}

.productivity-image{
    width: 41.5%;
    height: 40%;
}

.productivity-row-column{
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 30px;
    width: 50%;
}

.productivity-row-title-column{
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 0px;
}

.productivity-column-title{
    width: 75%;
    color: #082856;
    font-family: 'Inter', sans-serif;
    font-size: 22px;
    font-style: normal;
    font-weight: 600;
    line-height: 32px; /* 150% */
}

.productivity-column-subtitle{
    width: 78%;
    color: #979797;
    font-family: 'Inter', sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 23.8px; /* 148.75% */
    padding-top: 0px;
}

.productivity-check-column{
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    margin-left: 5%;
    gap: 16px;
}

.productivity-check-row{
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    gap: 8px;
}

.productivity-check-image{
    width: 24px;
    height: 24px;
}

.productivity-check-text{
    color: var(--Text-Black, #000);
    font-family: 'Inter', sans-serif;
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
}

.productivity-contact-button{
    display: flex;
    width: 208px;
    height: 53px;
    padding: 0px 0px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    border-radius: 5px;
    background: #082856;
    color: #FFF;
    font-family: 'Inter', sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    cursor: pointer;
}

@media screen and (max-width: 430px){

    .productivity-div{
        display: flex;
        width: 100%;
        padding: 0px 0px;
        flex-direction: column;
        align-items: center;
        gap: 17px;
    }

    .productivity-div-row{
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: flex-start;
        width: 100%;
        margin-left: 0px;
    }

    .productivity-image{
        width: 100%;
    }

    .productivity-row-column{
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;
        gap: 30px;
        width: 100%;
        padding-bottom: 20px;
    }

    .productivity-row-title-column{
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;
        gap: 0px;
        padding-top: 25px;
    }

    .productivity-column-title{
        width: 90%;
        color: #082856;
        font-family: 'Inter', sans-serif;
        font-size: 22px;
        font-style: normal;
        font-weight: 600;
        line-height: 32px; /* 150% */
    }
    
    .productivity-column-subtitle{
        width: 90%;
        color: #979797;
        font-family: 'Inter', sans-serif;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 23.8px; /* 148.75% */
        padding-top: 20px;
    }
}


/* Client Section */
.client-section-div{
    display: flex;
    width: 100%;
    padding: 50px 0px;
    justify-content: center;
    align-items: center;
    flex-direction: row;
    background: #082856;
}

.client-section-row{
    display: flex;
    width: 80%;
    padding: 0px 0px;
    justify-content: space-between;
    align-items: center;
    flex-direction: row;
    background: #082856;
}

.client-section-title {
    position: relative;
    color: #124999;
    text-align: center;
    font-family: 'Inter', sans-serif;
    font-size: 120px;
    font-style: normal;
    font-weight: 700;
    line-height: 178.5px;
    width: 100%;
    height: 100%;
}

.client-section-subtitle {
    display: flex;
    flex-direction: column;
    justify-content: center;
    position: absolute;
    color: #FFF;
    text-align: center;
    font-family: 'Inter', sans-serif;
    font-size: 21px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}


.client-section-divider {
    width: 1px;
    height: 70px;
    background: #146FF0;
}

@media screen and (max-width: 430px){

    .client-section-row{
        display: flex;
        width: 100%;
        padding: 0px 0px;
        justify-content: center;
        align-items: center;
        flex-direction: row;
        background: #082856;
    }


    .client-section-title-container-1{
        display: none;
    }

    .client-section-divider{
        display: none;
    }
}

/* Team Section */
.team-div{
    display: flex;
    padding: 100px 150px;
    flex-direction: column;
    align-items: flex-start;
    flex-shrink: 0;
    background: #FFF;
}

.team-section-header{
    color: var(--Primary-500, #34113F);
    font-family: 'Inter', sans-serif;
    font-size: 40px;
    font-style: normal;
    font-weight: 700;
    line-height: 61.1px; /* 152.75% */
}

.team-member-row {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: start;
    gap: 40px;
}

.team-member-row-1 {
    width: 66.0%;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: start;
    gap: 40px;
}

.team-member-card{
    width: 100%;
}

.team-member-div {
    width: 100%;
    height: 450px;
    border-radius: 15px;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
}

.team-member-name{
    color: #000;
    font-family: 'Inter', sans-serif;
    font-size: 22px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    padding-top: 20px;
}

.team-member-position{
    color: #000;
    font-family: 'Inter', sans-serif;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    padding-top: 14px;
}

@media screen and (max-width: 430px){

    .team-div{
        display: flex;
        padding: 90px 0px;
        flex-direction: column;
        align-items: center;
        justify-content: flex-start;
        flex-shrink: 0;
        background: #FFF;
    }

    .team-member-row {
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
        gap: 40px;
    }

    .team-member-row-1 {
        width: 100.0%;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
        gap: 40px;
    }

    .team-member-card{
        width: 80%;
    }

}

/* Story Section */
.story-div{
    display: flex;
    padding: 100px 150px;
    flex-direction: column;
    align-items: flex-start;
    background: #F7F7F7;
}

.story-section-header{
    color: var(--Primary-500, #34113F);
    font-family: 'Inter', sans-serif;
    font-size: 40px;
    font-style: normal;
    font-weight: 700;
    line-height: 61.1px; /* 152.75% */
}

.story-control-row{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 100%;
    gap: 10px;
    padding-top: 40px;
}

.story-control-button {
    display: flex;
    padding: 12px;
    justify-content: center;
    align-items: center;
    border-radius: 34.5px;
    border: 1.15px solid #EAEAE9;
    background: #FFF;
    cursor: pointer;
}

.story-control-button-1 {
    display: none;
    padding: 12px;
    justify-content: center;
    align-items: center;
    border-radius: 34.5px;
    border: 1.15px solid #EAEAE9;
    background: #FFF;
    cursor: pointer;
}

.story-card-container{
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 20px;
}

.story-card-container-1{
    width: 100%;
    display: none;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 20px;
}

.story-card {
    display: flex;
    width: 27.5%;
    padding-bottom: 25px;
    flex-direction: column;
    justify-content: start;
    align-items: start;
    gap: 20px;
    border-radius: 20px;
    border: 1px solid #CCC;
    background: #FFF;
}

.story-card-image {
    width: 100%;
    height: 250px;
    border-radius: 15px 15px 0px 0px;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
}

.story-card-date{
    color: #000;
    font-family: 'Inter', sans-serif;
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    padding-left: 20px;
}

.story-card-content{
    color: #000;
    font-family: 'Inter', sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 300;
    line-height: 30px; 
    padding-left: 20px;
}

@media screen and (max-width: 430px){
    .story-div{
        display: flex;
        padding: 90px 0px;
        flex-direction: column;
        align-items: center;
        background: #F7F7F7;
    }

    .story-card-container{
        width: 100%;
        display: none;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        gap: 20px;
    }
    
    .story-card-container-1{
        width: 100%;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        gap: 20px;
    }

    .story-card {
        display: flex;
        width: 100%;
        padding-bottom: 25px;
        flex-direction: column;
        justify-content: start;
        align-items: start;
        gap: 20px;
        border-radius: 20px;
        border: 1px solid #CCC;
        background: #FFF;
    }

    .story-control-button {
        display: none;
        padding: 12px;
        justify-content: center;
        align-items: center;
        border-radius: 34.5px;
        border: 1.15px solid #EAEAE9;
        background: #FFF;
        cursor: pointer;
    }
    
    .story-control-button-1 {
        display: flex;
        padding: 12px;
        justify-content: center;
        align-items: center;
        border-radius: 34.5px;
        border: 1.15px solid #EAEAE9;
        background: #FFF;
        cursor: pointer;
    }

    .story-control-row{
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        width: 95%;
        gap: 10px;
        padding-top: 40px;
    }
}

/* Contact Us */
.contact-us-div {
    display: flex;
    width: 100%;
    padding: 100px 0px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 10px;
    background: #082856;
}

.contact-us-heading{
    color: #FFF;
    text-align: center;
    font-family: 'Inter', sans-serif;
    font-size: 63px;
    font-style: normal;
    font-weight: 700;
    line-height: normal
}

.contact-us-button {
    display: flex;
    width: 208px;
    height: 53px;
    /* padding: 8px 20px; */
    justify-content: center;
    align-items: center;
    gap: 10px;
    border-radius: 5px;
    background: #F4F4F4;
    color: #082856;
    font-family: 'Inter', sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    margin-top: 50px;
    cursor: pointer;
}

@media screen and (max-width: 430px){

    .contact-us-heading{
        width: 75%;
        color: #FFF;
        text-align: center;
        font-family: 'Inter', sans-serif;
        font-size: 45px;
        font-style: normal;
        font-weight: 700;
        line-height: normal
    }

}
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100..900&display=swap');

html, body {
  overflow-x: hidden;
}
body {
  position: relative
}
/* Header */
.landing-header-row {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    margin: 75px 75px;
}

  .landing-review-column {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    flex-direction: column;
    justify-content: start;
    align-items: start;
    gap: 30px;
}

  
.landing-review-box {
    display: flex;
    padding: 20px 10px;
    flex-direction: column;
    align-items: flex-start;
    gap: 10px;
    border-radius: 5px;
    border: 0.5px solid rgba(0, 0, 0, 0.03);
    background: #FFF;
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.03), 0px 4px 4px 0px rgba(0, 0, 0, 0.03) inset;
    
}

.google-review-image{
    width: 315px;
    height: 72px;
}
  
.google-review-heading {
    color: #000;
    font-family: 'Inter', sans-serif;
    font-size: 36px;
    font-style: normal;
    font-weight: 800;
    line-height: normal;
}

.google-review-subheading {
    color: #000;
    font-family: 'Inter', sans-serif;
    font-size: 30px;
    font-style: italic;
    font-weight: 300;
    line-height: normal;
}

.google-review-thirdheading {
    color: #51636F;
    font-family: 'Inter', sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 300;
    line-height: 30px; /* 187.5% */
    width: 70%;
}
  
.google-book-demo {
    display: flex;
    width: 208px;
    height: 53px;
    /* padding: 8px 20px; */
    justify-content: center;
    align-items: center;
    gap: 10px;
    border-radius: 5px;
    background: #082856;
    color: #FFF;
    font-family: 'Inter', sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    cursor: pointer;
}

.header-pic-image{
  width: 541px;
  height: 522px;
  padding-top: 30px;
}

.mobile-header-pic-image{
  display: none;
  width: 100%;
  height: 343px;
}

.google-free-demo{
  margin-left:0px;
}

@media screen and (max-width: 430px){

  .landing-header-row {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin: 90px 10px;
  }

  .header-pic-image{
    display: none;
  }

  .mobile-header-pic-div{
    display: block;
  }

  .mobile-header-pic-image{
    width: 95vw;
    height: 41vh;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    display: block;
    /* padding-left: 10px; */
  }

  .landing-review-box {
    display: flex;
    padding: 20px 10px;
    flex-direction: column;
    align-items: flex-start;
    gap: 10px;
    border-radius: 5px;
    border: 0.5px solid rgba(0, 0, 0, 0.03);
    background: #FFF;
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.03), 0px 4px 4px 0px rgba(0, 0, 0, 0.03) inset;
    margin-left: 20px;
  }

  .google-review-image{
    width: 270px;
    height: 60px;
  }

  .google-review-heading {
    color: #000;
    font-family: 'Inter', sans-serif;
    font-size: 36px;
    font-style: normal;
    font-weight: 800;
    line-height: normal;
    margin-left: 20px;
  }

  .google-review-subheading {
    color: #000;
    font-family: 'Inter', sans-serif;
    font-size: 30px;
    font-style: italic;
    font-weight: 300;
    line-height: normal;
    margin-left: 20px;
  }

  .google-review-thirdheading {
    color: #51636F;
    font-family: 'Inter', sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 300;
    line-height: 30px; /* 187.5% */
    width: 80%;
    margin-left: 20px;
  }

  .google-book-demo {
    display: flex;
    width: 208px;
    height: 53px;
    /* padding: 8px 20px; */
    justify-content: center;
    align-items: center;
    gap: 10px;
    border-radius: 5px;
    background: #082856;
    color: #FFF;
    font-family: 'Inter', sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    cursor: pointer;
    margin-left: 0px;
  }

  .google-free-demo{
    margin-left:20px;
  }

}

/* Partners */
.partner-block{
  margin-left: 106px;
  margin-right: 106px;
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.partner-column{
  width: 100%;
  justify-content: center;
  align-items: center;
}

.partner-heading{
  color: #000;
  text-align: center;
  font-family: 'Inter', sans-serif;
  font-size: 36px;
  font-style: normal;
  font-weight: 500;
  line-height: 60px; 
  width: 60%;
  margin-bottom: 20px;
  margin-left: 21%;
}

.grid-container {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: repeat(2, auto);
  gap: 1.5px 0;
}

.mobile-grid-container{
  display: none;
}

.grid-item {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 20px;
}

.divider-horizontal {
  width: 100%;
  height: 1.5px;
  background: #F4F4F4;
  grid-column: 1 / span 3;
}

.divider-vertical {
  width: 1.5px;
  height: 290.975px;
  background: #F4F4F4;
  position: absolute;
  top: 0;
  bottom: 0;
}

.divider-vertical:nth-child(odd) {
  left: calc(100% / 3);
}

.divider-vertical:nth-child(even) {
  left: calc(2 * 100% / 3);
}

@media screen and (max-width: 430px){
  
  .partner-block{
    margin-left: 10px;
    margin-right: 10px;
    display: flex;
    flex-direction: row;
    justify-content: center;
  }

  .partner-column{
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  
  .partner-heading{
    color: #000;
    text-align: center;
    font-family: 'Inter', sans-serif;
    font-size: 24px;
    font-style: normal;
    font-weight: 500;
    line-height: 60px; 
    width: 75%;
    margin-bottom: 20px;
    margin-left: 0%;
  }

  .grid-container {
    display: none;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: repeat(3, auto);
    gap: 1.5px 0;
  }

  .mobile-grid-container{
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: repeat(3, auto);
    gap: 1.5px 0;
    padding-top: 20px;
  }
  
  .grid-item {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 20px;
  }
  
  .divider-horizontal {
    width: 100%;
    height: 1.5px;
    background: #F4F4F4;
    grid-column: 1 / span 2;
  }
  
  .divider-vertical {
    width: 1.5px;
    height: 290.975px;
    background: #F4F4F4;
    position: absolute;
    top: 0;
    bottom: 0;
  }
  
  .divider-vertical:nth-child(odd) {
    left: calc(100% / 2);
  }
  
  .divider-vertical:nth-child(even) {
    left: calc(2 * 100% / 2);
  }

}

/* faqs */
.faq-main-row {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  margin: 75px 0px;
}

.mobile-faq-main-row {
  display: none;
}

.image-holder {
  display: flex;
  width: 40%;
  height: 900px;
  padding: 0px 86px 0px 86px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  background: #321B73;
}

.image-holder img {
  max-width: 100%;
  max-height: 100%;
  display: block;
  margin: auto;
}

.faqs{
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  width: 50%;
  gap: 40px;
  margin-top: 50px;
}

.faq-block{
  display: flex;
  flex-direction: column;
  gap: 25px;
}

.faq-heading-row {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 85%;
  margin: 0px 50px;
  cursor: pointer;
}

.faq-heading{
  color: #000;
  text-align: center;
  font-family: 'Inter', sans-serif;
  font-size: 24px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.faq-subheading-row {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 85%;
  margin: 0px 25px;
}

.faq-subheading{
  color: rgba(13, 14, 16, 0.70);
  font-family: 'Inter', sans-serif;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  width: 75%;
}

.landing-faq-divider-row{
  display: flex;
  justify-content: center;
  align-items: center;
}

.landing-faq-divider {
  width: 85%;
  height: 2px;
  background: #EAEAE9;
}

@media screen and (max-width: 430px){
  .faq-main-row {
    display: none;
  }
  
  .mobile-faq-main-row {
    display: block;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    padding-top: 20px;
  }

  .mobile-faq-main-column {
    display: block;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    padding-top: 20px;
  }

  .mob-faq-image-container {
    display: flex;
    width: 95%;
    height: 454px;
    justify-content: center;
    align-items: center;
    gap: 4.98px;
    border-radius: 5px;
    background: #321B73;
    margin: 0px 10px;
  }

  .mob-faq-heading {
    color: #000;
    text-align: left;
    font-family: 'Inter', sans-serif;
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    margin: 9px 10px;
    margin-top: 25px;
    width: 90%;
  }

  .mob-faq-subheading {
    color: rgba(13, 14, 16, 0.70);
    font-family: 'Inter', sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin: 0px 10px;
    margin-top: 25px;
    text-align: left;
  }

  .mob-faq-thirdheading {
    color: #000;
    font-family: 'Inter', sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    margin: 0px 10px;
    margin-top: 25px;
    text-align: center;
  }
  
}

/* Contact Us */
.landing-contact-row{
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  width: 100%;
  height: 60%;
}

.landing-team-column{
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  width: 30%;
  gap: 40px;
}

.landing-team-column-heading{
  color: #000;
  text-align: center;
  font-family: 'Inter', sans-serif;
  font-size: 44px;
  font-style: normal;
  font-weight: 600;
  line-height: 60px; /* 136.364% */
  width: 230px;
}

.landing-contact-image{
  width: 190%;
}

.landing-advice-div{
  width: 70%;
  display: flex;
  padding: 60px;
  flex-direction: column;
  align-items: flex-start;
  gap: 40px;
  border-radius: 20px;
  background: #FFF;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.05), 0px 4px 4px 0px rgba(0, 0, 0, 0.05) inset;
}

.landing-advice-header{
  color: #000;
  text-align: center;
  font-family: 'Inter', sans-serif;
  font-size: 29px;
  font-style: normal;
  font-weight: 600;
  line-height: 60px; /* 206.897% */
}

.landing-advice-subbheader{
  color: #000;
  font-family: 'Inter', sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.landing-advice-feature-row{
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
}

.landing-advice-item-row{
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 10px;
}

.landing-advice-item-column{
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 14px;
}

.landing-advice-item-heading{
  color: #000;
  text-align: left;
  font-family: 'Inter', sans-serif;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.landing-advice-item-subheading{
  color: #000;
  font-family: 'Inter', sans-serif;
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  width: 60%;
}

.landing-advice-button-row{
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 40px;
}

.landing-advice-demo-button{
  display: flex;
  width: 208px;
  height: 53px;
  /* padding: 8px 20px; */
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 5px;
  background: #082856;
  color: #FFF;
  font-family: 'Inter', sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  cursor: pointer;
}

.landing-advice-whatsapp-button{
  display: flex;
  width: 208px;
  height: 53px;
  /* padding: 8px 20px; */
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 5px;
  background: #23B75A;
  color: #FFF;
  font-family: 'Inter', sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  cursor: pointer;
}

.landing-calendar-button-image{
  width: 24px;
  height: 24px;
}

.mob-landing-team-column{
  display: none;
}

@media screen and (max-width: 430px){

  .landing-contact-row{
    display: none;
    justify-content: flex-start;
    align-items: flex-start;
    width: 100%;
    /* height: 60%; */
  }
  
  .mob-landing-team-column{
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
    gap: 40px;
    padding-top: 50px;
  }

  .landing-team-column-heading{
    color: #000;
    text-align: center;
    font-family: 'Inter', sans-serif;
    font-size: 36px;
    font-style: normal;
    font-weight: 600;
    line-height: 60px; /* 136.364% */
    /* width: 230px; */
  }
  
  .landing-contact-image{
    width: 120%;
    margin-left: 20%;
  }

  .landing-advice-column{
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
    gap: 14px;
    /* padding-top: 50px; */
  }

  .landing-advice-header{
    color: #000;
    text-align: left;
    font-family: 'Inter', sans-serif;
    font-size: 29px;
    font-style: normal;
    font-weight: 600;
    line-height: 40px; /* 206.897% */
    width: 77.5%;
  }
  
  .landing-advice-subbheader{
    color: #000;
    font-family: 'Inter', sans-serif;
    font-size: 13px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    width: 77.5%;
  }

  .landing-advice-main-column{
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    width: 77.5%;
    gap: 50px;
    padding-top: 50px;
  }

  .landing-advice-item-subheading{
    color: #000;
    font-family: 'Inter', sans-serif;
    font-size: 15px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    width: 100%;
  }

  .mob-landing-advice-button-column{
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    width: 77.5%;
    gap: 20px;
    padding-top: 50px;
  }

}

/* Get Started */
.get-started-div {
  display: flex;
  width: 100%;
  padding: 88px 0px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 10px;
  background: #082856;
}

.get-started-heading {
  color: #FFF;
  text-align: center;
  font-family: 'Inter', sans-serif;
  font-size: 32px;
  font-style: normal;
  font-weight: 600;
  line-height: 60px;
  width: 100%;
}

.get-started-subheading {
  color: #EAEAE9;
  text-align: center;
  font-family: 'Inter', sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  width: 45%;
}

.get-started-button-row {
  display: flex;
  width: 45%;
  justify-content: center;
  align-items: center;
  margin-top: 50px;
}

.get-started-button {
  display: flex;
  width: 175px;
  height: 40px;
  padding: 8px 20px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 5px;
  background: #F4F4F4;
  color: #082856;
  font-family: 'Inter', sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  cursor: pointer;
}

.get-started-left-div{
  margin-left: 0px;
  display: flex;
}

.get-started-left-div-1{
  margin-left: 0px;
  display: none;
}

@media screen and (max-width: 430px){
  
  .get-started-div {
    display: flex;
    width: 100%;
    padding: 50px 0px;
    flex-direction: column;
    align-items: flex-start;
    gap: 31px;
    background: #082856;
    margin-top: 40px;
  }

  .get-started-heading {
    color: #FFF;
    text-align: left;
    font-family: 'Inter', sans-serif;
    font-size: 30px;
    font-style: normal;
    font-weight: 600;
    line-height: 46px;
    width: 90%;
    padding-left: 24px;
  }

  .get-started-subheading {
    color: #EAEAE9;
    text-align: left;
    font-family: 'Inter', sans-serif;
    font-size: 15px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    width: 90%;
    padding-left: 24px;
  }

  .get-started-button-row {
    display: flex;
    width: 45%;
    justify-content: center;
    align-items: center;
    margin-top: 0px;
  }

  .get-started-button {
    display: flex;
    width: 175px;
    height: 40px;
    padding: 8px 20px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    border-radius: 5px;
    background: #F4F4F4;
    color: #082856;
    font-family: 'Inter', sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    cursor: pointer;
    margin-left: 24px;
  }

  .get-started-left-div{
    margin-left: 10%;
    display: none;
  }

  .get-started-left-div-1{
    display: flex;
  }


}
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100..900&display=swap');

/* Feature Header */
.landing-header-container {
    display: flex;
    width: 100vw;
    /* height: 100vh; */
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    gap: 20px;
    flex-shrink: 0;
    background: #FFF;
    /* height: 1450px; */
    padding-bottom: 50px;
}

.landing-header-heading {
    width: 475px;
    flex-shrink: 0;
    color: #161C2D;
    text-align: center;
    font-family: 'Inter', sans-serif;
    font-size: 30px;
    font-style: normal;
    font-weight: 700;
    line-height: 40px;
    padding-top: 100px;
}

.landing-header-subheading {
    width: 560px;
    color: #51636F;
    text-align: center;
    font-family: 'Inter', sans-serif;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 32px;
    letter-spacing: -0.2px;
}

.feature-landing-button-row {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 20px;
}

.feature-landing-contact-btn {
    display: flex;
    width: 170px;
    height: 52px;
    justify-content: center;
    align-items: center;
    border-radius: 5px;
    border: 1px solid #082856;
    color: #161C2D;
    text-align: center;
    font-family: 'Inter', sans-serif;
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    line-height: 32px; /* 177.778% */
    letter-spacing: -0.6px;
    cursor: pointer;
}

.feature-landing-row {
    width: 100%;
    background-color: transparent;
    display: flex;
    justify-content: center;
    align-items: center;
}

.feature-landing-reso {
    width: 848px;
    height: 562px;
    margin-top: 80px;
    box-shadow: inset 0 0 0 1px rgba(255, 255, 255, 0.2),
    0 4px 8px rgba(17, 20, 24, 0.4),
    0 18px 46px 6px rgba(17, 20, 24, 0.4);
    opacity: 0;
    transition: opacity 1s ease;
}

.feature-landing-reso-1 {
    width: 510px;
    height: 620px;
    margin-top: 80px;
    /* box-shadow: inset
    0 0 0 1px rgba(255, 255, 255, 0.2),
      0 4px 8px rgba(17, 20, 24, 0.4),
      0 18px 46px 6px rgba(17, 20, 24, 0.4); */
    /* background-color: transparent; */
    opacity: 0;
    transition: opacity 1s ease;
}

/* .fade-in {
  animation: fadeInAnimation 1s ease forwards;
} */

.feature-landing-reso.fade-in,
.feature-landing-reso-1.fade-in {
    opacity: 1; /* Show the images with fade-in effect */
}

/* @keyframes fadeInAnimation {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
} */

.feature-landing-second-row {
    width: 85%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: flex-start;
    gap: 88px;
    margin-top: 100px;
}

.feature-landing-second-row-1 {
    width: 75%;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    gap: 88px;
    margin-top: 100px;
}

.feature-landing-second-column {
    width: 23.5%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 27px;
}

.feature-landing-second-column-1 {
    width: 26%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 27px;
}

.feature-landing-second-img {
    width: 35px;
    height: 35px;
}

.feature-landing-second-column-heading {
    color: #161C2D;
    font-family: 'Inter', sans-serif;
    font-size: 21px;
    font-style: normal;
    font-weight: 700;
    line-height: 32px; /* 152.381% */
    letter-spacing: -0.5px;
}

.feature-landing-second-column-subheading {
    text-align: left;
    font-family: 'Inter', sans-serif;
    color: #161C2D;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 29px; /* 181.25% */
    letter-spacing: -0.2px;
    opacity: 0.7;
}

.feature-landing-second-column-subheading-1 {
    text-align: left;
    font-family: 'Inter', sans-serif;
    color: #161C2D;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 29px; /* 181.25% */
    letter-spacing: -0.2px;
    opacity: 0.7;
    width: 90%;
}

.landing-header-link {
    color: #321B73;
    text-align: center;
    font-family: 'Inter', sans-serif;
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    cursor: pointer;
}


@media screen and (max-width: 430px) {

    .landing-header-container {
        display: flex;
        width: 100vw;
        /* height: 100vh; */
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;
        gap: 20px;
        flex-shrink: 0;
        background: #FFF;
        padding-bottom: 20px;
        /* height: 2220px; */
    }

    .landing-header-heading {
        width: 92.5%;
        flex-shrink: 0;
        color: #161C2D;
        text-align: center;
        font-family: 'Inter', sans-serif;
        font-size: 38px;
        font-style: normal;
        font-weight: 600;
        line-height: 58px; /* 152.632% */
        letter-spacing: -1.8px;
        padding-top: 75px;
    }

    .landing-header-subheading {
        width: 100%;
        color: #51636F;
        text-align: center;
        font-family: 'Inter', sans-serif;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 32px;
        letter-spacing: -0.2px;
    }

    .feature-landing-button-row {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 20px;
    }

    .feature-landing-contact-btn {
        display: flex;
        width: 210px;
        height: 52px;
        justify-content: center;
        align-items: center;
        border-radius: 5px;
        border: 1px solid #082856;
        color: #161C2D;
        text-align: center;
        font-family: 'Inter', sans-serif;
        font-size: 18px;
        font-style: normal;
        font-weight: 700;
        line-height: 32px; /* 177.778% */
        letter-spacing: -0.6px;
        cursor: pointer;
    }

    .feature-landing-reso {
        width: 95%;
        height: 285px;
        margin-top: 40px;
        box-shadow: inset 0 0 0 1px rgba(255, 255, 255, 0.2),
        0 4px 8px rgba(17, 20, 24, 0.4),
        0 18px 46px 6px rgba(17, 20, 24, 0.4);
        opacity: 0;
        transition: opacity 1s ease;
    }

    .feature-landing-reso-1 {
        width: 100%;
        height: 500px;
        margin-top: 40px;
        /* box-shadow: inset
        0 0 0 1px rgba(255, 255, 255, 0.2),
          0 4px 8px rgba(17, 20, 24, 0.4),
          0 18px 46px 6px rgba(17, 20, 24, 0.4); */
        /* background-color: transparent; */
        opacity: 0;
        transition: opacity 1s ease;
    }


    .feature-landing-second-row {
        width: 85%;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
        gap: 88px;
        margin-top: 100px;
    }

    .feature-landing-second-row-1 {
        width: 85%;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
        gap: 88px;
        margin-top: 100px;
    }

    .feature-landing-second-column {
        width: 80%;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
        gap: 27px;
    }

    .feature-landing-second-column-1 {
        width: 90%;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
        gap: 27px;
    }

    .feature-landing-second-img {
        width: 35px;
        height: 35px;
    }

    .feature-landing-second-column-heading {
        color: #161C2D;
        font-family: 'Inter', sans-serif;
        font-size: 21px;
        font-style: normal;
        font-weight: 700;
        line-height: 32px; /* 152.381% */
        letter-spacing: -0.5px;
    }

    .feature-landing-second-column-subheading {
        text-align: left;
        font-family: 'Inter', sans-serif;
        color: #161C2D;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 29px; /* 181.25% */
        letter-spacing: -0.2px;
        opacity: 0.7;
    }

    .feature-landing-second-column-subheading-1 {
        text-align: left;
        font-family: 'Inter', sans-serif;
        color: #161C2D;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 29px; /* 181.25% */
        letter-spacing: -0.2px;
        opacity: 0.7;
        width: 90%;
    }

}

/* Feature Block */
.feature-block-div {
    display: flex;
    width: 100%;
    padding: 40px 0px;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    gap: 10px;
    background: #E9F7F8;
}

.feature-block-column {
    display: flex;
    flex-direction: column;
    justify-content: start;
    align-items: start;
    gap: 35px;
    margin-left: 145px;
}

.feature-block-section {
    color: #34A5EB;
    font-family: 'Inter', sans-serif;
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
}

.feature-block-heading {
    color: #000;
    font-family: 'Inter', sans-serif;
    font-size: 36px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    width: 60%;
}

.feature-block-subheading {
    color: #51636F;
    font-family: 'Inter', sans-serif;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 30px;
    width: 50%;
}

.features-row {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 85%;
}

.feature-row-heading-column {
    display: flex;
    flex-direction: column;
    justify-content: start;
    align-items: start;
}

.features-row-heading {
    display: flex;
    flex-direction: row;
    justify-content: start;
    align-items: start;
    width: 50%;
    /* margin-right: 5%; */
}

.feature-grid-heading {
    color: #000;
    text-align: left;
    font-family: 'Inter', sans-serif;
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    margin-left: 12px;
}

.feature-grid-subheading {
    color: #000;
    text-align: left;
    font-family: 'Inter', sans-serif;
    font-size: 13px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin-left: 12px;
    width: 85%;
}

.feature-grid-subheading-1 {
    color: #000;
    text-align: left;
    font-family: 'Inter', sans-serif;
    font-size: 13px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin-left: 12px;
    width: 60%;
}

.feature-btn-row {
    display: flex;
    flex-direction: row;
    justify-content: start;
    align-items: start;
    gap: 20px;
}

.feature-grid-book-demo {
    display: flex;
    width: 208px;
    height: 53px;
    padding: 0px 0px;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 10px;
    border-radius: 5px;
    background: #082856;
    color: #FFF;
    font-family: 'Inter', sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    cursor: pointer;
}

.feature-whatsapp-btn {
    display: flex;
    width: 208px;
    height: 53px;
    padding: 0px 0px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    border-radius: 5px;
    background: #23B75A;
    color: #FFF;
    font-family: 'Inter', sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    cursor: pointer;
}


.feature-mobile-image {
    padding-right: 50px;
}

.mob-feature-mobile-image-row {
    display: none;
}

.mob-feature-mobile-image {
    display: none;
}

.feature-showcase {
    display: flex;
    flex-direction: column;
    gap: 25px;
}

.mobile-feature-showcase {
    display: none;
    flex-direction: column;
    gap: 25px;
}

.feature-points-row {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: flex-start;
    gap: 20px;
    width: 100%;
    padding-bottom: 30px;
    padding-left: 0px;
}

.feature-points-column {
    display: flex;
    flex-direction: column;
}

.feature-points-column-text {
    width: 400px;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    color: #51636F;
    text-align: left;
    font-family: 'Inter', sans-serif;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 32px;
    letter-spacing: -0.2px;
    padding-top: 12px;
}

@media screen and (max-width: 430px) {

    .feature-points-row {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;
        gap: 0px;
        width: 100%;
        padding-bottom: 30px;
        padding-left: 60px;
    }

    .feature-showcase {
        display: none;
    }

    .mobile-feature-showcase {
        display: flex;
    }

    .feature-block-div {
        display: flex;
        width: 100%;
        padding: 40px 0px;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        gap: 10px;
        background: #E9F7F8;
    }

    .feature-block-column {
        display: flex;
        flex-direction: column;
        justify-content: start;
        align-items: start;
        gap: 35px;
        margin-left: 36px;
    }

    .feature-block-heading {
        color: #000;
        font-family: 'Inter', sans-serif;
        font-size: 36px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
        width: 80%;
    }

    .feature-block-subheading {
        color: #51636F;
        font-family: 'Inter', sans-serif;
        font-size: 18px;
        font-style: normal;
        font-weight: 400;
        line-height: 30px;
        width: 60%;
    }

    .features-row {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        width: 100%;
    }

    .feature-row-heading-column {
        display: flex;
        flex-direction: column;
        justify-content: start;
        align-items: start;
    }

    .features-row-heading {
        display: flex;
        flex-direction: row;
        justify-content: start;
        align-items: start;
        width: 100%;
        /* margin-right: 5%; */
    }

    .feature-grid-heading {
        color: #000;
        text-align: left;
        font-family: 'Inter', sans-serif;
        font-size: 20px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        margin-left: 12px;
    }

    .feature-grid-subheading {
        color: #000;
        text-align: left;
        font-family: 'Inter', sans-serif;
        font-size: 13px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        margin-left: 12px;
        width: 100%;
    }

    .feature-grid-subheading-1 {
        color: #000;
        text-align: left;
        font-family: 'Inter', sans-serif;
        font-size: 13px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        margin-left: 12px;
        width: 72.5%;
    }

    .feature-btn-row {
        display: flex;
        flex-direction: column;
        justify-content: start;
        align-items: start;
        gap: 20px;
    }

    .feature-mobile-image {
        display: none;
    }

    .mob-feature-mobile-image {
        display: block;
    }

    .mob-feature-mobile-image-row {
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        padding-top: 20px;
    }

    .feature-points-column-text {
        width: 80%;
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: flex-start;
        color: #51636F;
        text-align: left;
        font-family: 'Inter', sans-serif;
        font-size: 18px;
        font-style: normal;
        font-weight: 400;
        line-height: 32px;
        letter-spacing: -0.2px;
        padding-top: 12px;
    }
}

/* Management Section */
.tabs-section {
    margin: 80px 80px;
}

.tab-label {
    color: rgba(60, 60, 67, 0.80);
    text-align: center;
    font-family: 'Inter', sans-serif;
    font-size: 16.1px;
    font-style: normal;
    font-weight: 400;
    line-height: 24.15px;
    letter-spacing: 0.287px;
    width: 325px;
}

.management-heading {
    color: #000;
    font-family: 'Inter', sans-serif;
    font-size: 36px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    padding-top: 50px;
}

.management-subheading {
    width: 386.895px;
    color: #51636F;
    font-family: 'Inter', sans-serif;
    font-size: 18px;
    font-style: normal;
    font-weight: 300;
    line-height: 30px;
    padding-top: 14px;
}

.management-search {
    display: flex;
    width: 179px;
    padding: 3.898px;
    align-items: center;
    gap: 7.796px;
    border-radius: 3.898px;
    border: 0.25px solid #D7D7D7;
    background: #FFFAF9;
    margin-top: 100px;
}

.management-search-field {
    border: none;
    background-color: #FFFAF9;
    outline: none;
}

.management-grid-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: start;
    gap: 40px;
    margin-top: 20px;
}

.management-grid-item {
    width: calc(25% - 16px); /* 33.33% minus margin */
    padding: 48px 36.568px 59px 32px;
    border-radius: 20px;
    border: 1px solid #CCC;
    background: #FFF;
}

.management-grid-heading {
    color: #000;
    font-family: 'Inter', sans-serif;
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    margin-top: 20px;
}

.management-grid-subheading {
    color: #000;
    font-family: 'Inter', sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 300;
    line-height: 30px;
    margin-top: 24px;
}

@media screen and (max-width: 430px) {
    .tabs-section {
        margin: 40px 10px;
    }

    .tab-label {
        color: rgba(60, 60, 67, 0.80);
        text-align: center;
        font-family: 'Inter', sans-serif;
        font-size: 16.1px;
        font-style: normal;
        font-weight: 400;
        line-height: 24.15px;
        letter-spacing: 0.287px;
        width: 49.5%;
    }

    .management-subheading {
        width: 92.5%;
        color: #51636F;
        font-family: 'Inter', sans-serif;
        font-size: 18px;
        font-style: normal;
        font-weight: 300;
        line-height: 30px;
        padding-top: 14px;
    }

    .management-search {
        display: flex;
        width: 179px;
        padding: 3.898px;
        align-items: center;
        gap: 7.796px;
        border-radius: 3.898px;
        border: 0.25px solid #D7D7D7;
        background: #FFFAF9;
        margin-top: 40px;
    }

    .management-grid-container {
        display: flex;
        flex-wrap: wrap;
        justify-content: start;
        gap: 40px;
        margin-top: 20px;
    }

    .management-grid-item {
        width: calc(100% - 16px); /* 33.33% minus margin */
        padding: 48px 36.568px 59px 32px;
        border-radius: 20px;
        border: 1px solid #CCC;
        background: #FFF;
    }

}

/* Download Section */
.download-div {
    display: flex;
    flex-direction: row;
    justify-content: start;
    width: 100%;
    padding: 110px 0px 95px 146px;
    align-items: center;
    background: #082856;
    position: relative;
}

.download-div-heading {
    color: #FFF;
    font-family: 'Inter', sans-serif;
    font-size: 45px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
}

.download-button-row {
    display: flex;
    flex-direction: row;
    justify-content: start;
    width: 100%;
    align-items: center;
    gap: 20px;
    color: #FFF;
    font-family: 'Inter', sans-serif;
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
}

.download-button-row-1 {
    display: flex;
    flex-direction: row;
    justify-content: start;
    width: 100%;
    align-items: center;
    gap: 20px;
    color: #FFF;
    font-family: 'Inter', sans-serif;
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
}

.download-button {
    width: 180px;
    height: 50px;
}

.download-screenshot {
    width: 300px;
    height: 550px;
}

.download-img-container {
    position: absolute;
    top: -45px;
    right: 250px;
    z-index: 1; /* Ensure the image stays above the content */
}

.mob-download-img-container {
    display: none;
    position: absolute;
    bottom: -45px;
    left: 20px;
    z-index: 1; /* Ensure the image stays above the content */
}

.mob-download-grey-tick {
    width: 20px;
    height: 20px;
}

.new-landing-mob-height {
        height: 0;
    }

@media screen and (max-width: 430px) {

    .download-div {
        display: flex;
        flex-direction: column;
        justify-content: start;
        width: 100%;
        height: 750px;
        padding: 47.5px 0px 0px 0px;
        align-items: center;
        background: #082856;
        position: relative;
    }

    .download-img-container {
        display: none;
        position: absolute;
        top: -45px;
        right: 250px;
        z-index: 1; /* Ensure the image stays above the content */
    }

    .mob-download-img-container {
        display: block;
        position: absolute;
        bottom: -240px;
        left: 13.5%;
        z-index: 1; /* Ensure the image stays above the content */
    }

    .download-button-row {
        display: flex;
        flex-direction: column;
        justify-content: start;
        width: 100%;
        align-items: center;
        gap: 20px;
        color: #FFF;
        font-family: 'Inter', sans-serif;
        font-size: 20px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
    }

    .download-button-row-1 {
        display: flex;
        flex-direction: row;
        justify-content: center;
        width: 100%;
        align-items: center;
        gap: 20px;
        color: #FFF;
        font-family: 'Inter', sans-serif;
        font-size: 20px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
    }

    .download-button {
        width: 180px;
        height: 52px;
    }

    .download-div-heading {
        color: #FFF;
        font-family: 'Inter', sans-serif;
        font-size: 45px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
        padding: 0 32px 0 32px;
    }

}

/* Business Potential */
.business-potential-div {
    display: flex;
    width: 100%;
    padding: 150px 146px 70px 146px;
    flex-direction: column;
    justify-content: start;
    align-items: start;
    gap: 10px;
    background: #F5F5F7;
}

.business-potential-heading {
    color: #000;
    font-family: 'Inter', sans-serif;
    font-size: 36px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
}

.business-potential-subheading {
    color: #51636F;
    font-family: 'Inter', sans-serif;
    font-size: 18px;
    font-style: normal;
    font-weight: 300;
    line-height: 30px;
}

.potential-grid-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: start;
    gap: 40px;
    margin-top: 20px;
}

.potential-grid-item {
    width: calc(25% - 16px); /* 33.33% minus margin */
    padding: 20px 0px 0px 0px;
    border-radius: 20px;
    /* border: 1px solid #CCC; */
    /* background: #FFF; */
}

.potential-grid-heading {
    color: #000;
    text-align: left;
    font-family: 'Inter', sans-serif;
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    padding-top: 30px;
}

.potential-grid-subheading {
    color: #000;
    font-family: 'Inter', sans-serif;
    font-size: 15px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    width: 77.5%;
    padding-top: 20px;
}

@media screen and (max-width: 430px) {

    .business-potential-div {
        display: flex;
        width: 100%;
        padding: 140px 0px 40px 36px;
        flex-direction: column;
        justify-content: start;
        align-items: start;
        gap: 10px;
        background: #F5F5F7;
    }

    .business-potential-heading {
        width: 50%;
        color: #000;
        font-family: 'Inter', sans-serif;
        font-size: 36px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
    }

    .business-potential-subheading {
        width: 70%;
        color: #51636F;
        font-family: 'Inter', sans-serif;
        font-size: 18px;
        font-style: normal;
        font-weight: 300;
        line-height: 30px;
    }

    .potential-grid-item {
        width: calc(100% - 16px); /* 33.33% minus margin */
        padding: 20px 0px 0px 0px;
        border-radius: 20px;
        /* border: 1px solid #CCC; */
        /* background: #FFF; */
    }

    .potential-grid-subheading {
        color: #000;
        font-family: 'Inter', sans-serif;
        font-size: 15px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        width: 65%;
        padding-top: 20px;
    }

}
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100..900&display=swap');

/* Pricing Plans */
.terms-header{
    color: #082856;
    text-align: center;
    font-family: 'Inter', sans-serif;
    font-size: 64px;
    font-style: normal;
    font-weight: 700;
    line-height: 76px;
    margin-top: 50px;
}

.terms-column{
    display: flex;
    flex-direction: column;
    padding-top: 48px;
    padding-left: 12.5%;
    padding-right: 12.5%;
    gap: 32px;
}

.terms-subheader-column{
    display: flex;
    flex-direction: column;
    gap: 8px;
}


.terms-subheader{
    color: var(--Grey-700, #333);
    font-family: 'Inter', sans-serif;
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: 32px;
}

.terms-text{
    color: var(--Grey-700, #333);
    font-feature-settings: 'cv05' on;
    font-family: 'Inter', sans-serif;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 28px;
}

.terms-text-bold{
    color: var(--Grey-700, #333);
    font-feature-settings: 'cv05' on;
    font-family: 'Inter', sans-serif;
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: 28px;
}

th{
    color: var(--Grey-700, #333);
    font-feature-settings: 'cv05' on;
    font-family: 'Inter', sans-serif;
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: 28px;
    border-bottom: 1px solid black;
    height: 50px;
    text-align: left;
}

td{
    color: var(--Grey-700, #333);
    font-feature-settings: 'cv05' on;
    font-family: 'Inter', sans-serif;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 28px;
    border-bottom: 1px solid black;
    height: 50px;
}

.terms-ul {
    list-style-type: disc;
    padding-top: 0;
    margin-top: 0;
  }

li {
    padding-top: 5px;
  }

.privacy-first-text{
    color: var(--Grey-700, #333);
    font-feature-settings: 'cv05' on;
    font-family: 'Inter', sans-serif;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 28px;
    padding-left: 12.5%;
    padding-right: 12.5%;
    padding-top: 28px;
}

.terms-text a {
    text-decoration: none; /* Remove underline */
    color: #0095ff; /* Change color to red (replace with desired color) */
  }

@media screen and (max-width: 430px){

    .terms-header{
        color: #082856;
        text-align: center;
        font-family: 'Inter', sans-serif;
        font-size: 48px;
        font-style: normal;
        font-weight: 700;
        line-height: 50px;
        margin-top: 50px;
    }

}
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100..900&display=swap');

/* Pricing Plans */
.pricing-plans-header {
    width: 100%;
    background: #F5F5F7;
    display: flex;
    flex-direction: column;
    gap: 75px;
    align-items: center;
}

.pricing-plans-column {
    display: flex;
    flex-direction: column;
    padding-top: 50px;
}

.pricing-heading-column {
    display: flex;
    flex-direction: column;
    gap: 30px;
    justify-content: center;
    align-items: center;
}

.pricing-switch-row {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

.pricing-switch-row-bold {
    color: #11305C;
    text-align: right;
    font-family: 'Inter', sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
}

.pricing-switch-row-normal {
    color: #11305C;
    font-family: 'Inter', sans-serif;
    font-size: 17px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

.pricing-plans-preheading {
    color: #000;
    text-align: center;
    font-family: 'Inter', sans-serif;
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
}

.pricing-plans-heading {
    color: #000;
    font-family: 'Inter', sans-serif;
    font-size: 45px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    text-align: center;
}

.pricing-plans-subheading {
    color: #51636F;
    text-align: center;
    font-family: 'Inter', sans-serif;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 30px; /* 166.667% */
    width: 70%;
}

.pricing-model-row {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 15px;
}

.pricing-model-card {
    display: flex;
    flex-direction: column;
    padding: 30px 24px;
    align-items: flex-start;
    justify-content: space-between;
    gap: 10px;
    /*border-radius: 15px;*/
    /*background: #FFF;*/
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.08), 0px 4px 4px 0px rgba(0, 0, 0, 0.08) inset;
    width: 21%;
    height: 750px;
    border-radius: 24px;
    border: 1px solid #015AA3;
    background: #FFF;
}

.check-list-row {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 10px;
    color: #000;
    text-align: left;
    font-family: 'Inter', sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

.pricing-card-name {
    color: #000;
    text-align: left;
    font-family: 'Inter', sans-serif;
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: 30px; /* 125% */
}

.pricing-card-best {
    color: #000;
    text-align: center;
    font-family: 'Inter', sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: 30px;
}



.pricing-card-prices {
    color: #000;
    text-align: center;
    font-family: 'Inter', sans-serif;
    font-size: 34px;
    font-style: normal;
    font-weight: 600;
    line-height: 30px; /* 75% */
    padding-top: 10px;
}

.pricing-card-prices-subheading {
    color: #000;
    text-align: center;
    font-family: 'Inter', sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 300;
    line-height: 30px; /* 214.286% */
}

.pricing-card-elite-subheading {
    color: #000;
    text-align: center;
    font-family: 'Inter', sans-serif;
    font-size: 13px;
    font-style: normal;
    font-weight: 300;
    line-height: 30px;
    padding-top: 30px;

}

.pricing-card-billed {
    color: #62A4CC;
    text-align: center;
    font-family: 'Inter', sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 30px; /* 214.286% */
}

.pricing-card-dollar-row {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    color: #000;
    text-align: left;
    font-family: 'Inter', sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin-top: 12px;
}

.pricing-card-dollar {
    color: #181059;
    font-family: 'Inter', sans-serif;
    font-size: 17px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

.pricing-card-dollar-price {
    opacity: 0.96;
    color: #181059;
    font-family: 'Inter', sans-serif;
    font-size: 25px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
}

.pricing-card-dollar-month {
    color: #181059;
    font-family: 'Inter', sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

.book-demo-btn {
    display: flex;
    width: 100%;
    padding: 7.5px 0px;
    justify-content: center;
    align-items: center;
    border-radius: 5px;
    background: #082856;
    color: #FFF;
    text-align: center;
    font-family: 'Inter', sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 30px;
    cursor: pointer;
    margin-top: 12px;
}

.get-started-btn {
    display: flex;
    width: 100%;
    padding: 7.5px 0px;
    justify-content: center;
    align-items: center;
    border-radius: 5px;
    border: 1px solid #082856;
    color: #082856;
    text-align: center;
    font-family: 'Inter', sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 30px;
    cursor: pointer;
    margin-top: 12px;
}

@keyframes bounce {
    0%, 20%, 50%, 80%, 100% {
        transform: translateY(0);
    }
    40% {
        transform: translateY(-25px); /* Increase this value for a higher bounce */
    }
    60% {
        transform: translateY(-12px); /* Adjust this value for a smoother bounce */
    }
}

.pricing-model-card-check-list {
    color: #181059;
}

.pricing-model-card:hover {
    background: #181059;
    animation: bounce 1s ease;
}

.pricing-model-card:hover .pricing-model-card-check-list {
    color: #fff;
}

.pricing-model-card:hover .check-list-row {
    color: #fff; /* Change text color on hover */
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 10px;
    text-align: left;
    font-family: 'Inter', sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

.pricing-model-card:hover .pricing-card-name {
    color: #fff;
    text-align: left;
    font-family: 'Inter', sans-serif;
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: 30px; /* 125% */
}

.pricing-model-card:hover .pricing-card-dollar {
    color: #fff;
    font-family: 'Inter', sans-serif;
    font-size: 17px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

.pricing-model-card:hover .pricing-card-dollar-price {
    opacity: 0.96;
    color: #fff;
    font-family: 'Inter', sans-serif;
    font-size: 25px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
}

.pricing-model-card:hover .pricing-card-dollar-month {
    color: #fff;
    font-family: 'Inter', sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

.pricing-model-card:hover .book-demo-btn {
    display: flex;
    width: 100%;
    padding: 7.5px 0px;
    justify-content: center;
    align-items: center;
    border-radius: 5px;
    background: #fff;
    color: #181059;
    text-align: center;
    font-family: 'Inter', sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 30px;
    cursor: pointer;
    margin-top: 12px;
}

.plans-features-header {
    color: #000;
    text-align: left;
    font-family: 'Inter', sans-serif;
    font-size: 15px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    padding-top: 20px;
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
}

.check-list-column {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 24px;
    padding-top: 10px;
}

.pricing-terms {
    color: #0A142F;
    text-align: center;
    font-family: 'Inter', sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 30px; /* 214.286% */
    width: 39%;
    margin-bottom: 25px;
}

@media screen and (max-width: 430px) {

    .pricing-heading-column {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 30px;
    }

    .pricing-plans-heading {
        color: #000;
        font-family: 'Inter', sans-serif;
        font-size: 36px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        text-align: center;
        width: 80%;
    }

    .pricing-plans-subheading {
        color: #51636F;
        text-align: center;
        font-family: 'Inter', sans-serif;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 30px; /* 166.667% */
        width: 80%;
    }

    .pricing-model-row {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 15px;
        width: 95%;
    }

    .pricing-model-card {
        display: flex;
        flex-direction: column;
        padding: 30px 24px;
        align-items: center;
        justify-content: space-between;
        gap: 10px;
        border-radius: 15px;
        background: #FFF;
        box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.08), 0px 4px 4px 0px rgba(0, 0, 0, 0.08) inset;
        width: 80%;
        height: 860px;
    }

    .pricing-terms {
        color: #0A142F;
        text-align: center;
        font-family: 'Inter', sans-serif;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 30px; /* 214.286% */
        width: 95%;
        margin-bottom: 25px;
    }

}

/* Offer Section */
.offer-section-div {
    display: flex;
    width: 100%;
    /* height: 1225px; */
    padding: 100px 150px;
    flex-direction: column;
    align-items: flex-start;
    background: #FFF;
}

.offer-section-heading {
    color: var(--Primary-500, #34113F);
    font-family: 'Inter', sans-serif;
    font-size: 40px;
    font-style: normal;
    font-weight: 700;
    line-height: 61.1px; /* 152.75% */
}

.offer-section-subheading {
    color: #51636F;
    font-family: 'Inter', sans-serif;
    font-size: 18px;
    font-style: normal;
    font-weight: 300;
    line-height: 30px;
    width: 30%;
}

.pricing-grid-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: start;
    gap: 40px;
    margin-top: 20px;
}

.pricing-grid-item {
    width: calc(21.5% - 16px); /* 33.33% minus margin */
    padding: 48px 36.568px 59px 32px;
    border-radius: 20px;
    border: 1px solid #CCC;
    background: #FFF;
}

.pricing-grid-heading {
    color: #000;
    font-family: 'Inter', sans-serif;
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    margin-top: 20px;
}

.pricing-grid-subheading {
    color: #000;
    font-family: 'Inter', sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 300;
    line-height: 30px;
    margin-top: 24px;
}

@media screen and (max-width: 430px) {

    .offer-section-div {
        display: flex;
        width: 100%;
        /* height: 1225px; */
        padding: 100px 18px;
        flex-direction: column;
        align-items: flex-start;
        background: #FFF;
    }

    .offer-section-heading {
        color: var(--Primary-500, #34113F);
        font-family: 'Inter', sans-serif;
        font-size: 40px;
        font-style: normal;
        font-weight: 700;
        line-height: 61.1px; /* 152.75% */
        width: 75%;
    }

    .offer-section-subheading {
        color: #51636F;
        font-family: 'Inter', sans-serif;
        font-size: 18px;
        font-style: normal;
        font-weight: 300;
        line-height: 30px;
        width: 80%;
    }

    .pricing-grid-item {
        width: calc(78% - 16px); /* 33.33% minus margin */
        padding: 48px 36.568px 59px 32px;
        border-radius: 20px;
        border: 1px solid #CCC;
        background: #FFF;
    }

    .pricing-grid-subheading {
        color: #000;
        font-family: 'Inter', sans-serif;
        font-size: 16px;
        font-style: normal;
        font-weight: 300;
        line-height: 30px;
        margin-top: 24px;
        width: 90%;
    }
}

/* FAQ Section */
.faq-section-div {
    width: 100%;
    background: #F5F5F7;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 100px;
    padding-bottom: 100px;
}

.faq-section-heading {
    color: #121212;
    font-family: 'Inter', sans-serif;
    font-size: 34px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
}

.faq-box-column {
    width: 100%;
    background: #F5F5F7;
    display: flex;
    flex-direction: column;
    gap: 50px;
    align-items: center;
    padding-top: 75px;
}

.pricing-faq-divider {
    width: 50%;
    height: 2px;
    background-color: #EAEAE9;
}

.faq-title-row {
    width: 49%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
}

.faq-title-style {
    color: #000;
    text-align: center;
    font-family: 'Inter', sans-serif;
    font-size: 24px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
}

.faq-answer-row {
    width: 49%;
    display: flex;
    flex-direction: row;
    justify-content: start;
    align-items: center;
}

.faq-answer-style {
    color: rgba(13, 14, 16, 0.70);
    font-family: 'Inter', sans-serif;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

@media screen and (max-width: 430px) {

    .faq-section-div {
        width: 100%;
        background: #F5F5F7;
        display: flex;
        flex-direction: column;
        align-items: center;
        padding-top: 65px;
        padding-bottom: 100px;
        padding-right: 18px;
    }

    .faq-section-heading {
        color: #121212;
        font-family: 'Inter', sans-serif;
        font-size: 34px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
        text-align: center;
    }

    .faq-box-column {
        width: 100%;
        background: #F5F5F7;
        display: flex;
        flex-direction: column;
        gap: 32px;
        align-items: center;
        padding-top: 75px;
    }

    .pricing-faq-divider {
        width: 90%;
        height: 2px;
        background-color: #EAEAE9;
    }

    .faq-title-row {
        width: 85%;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        cursor: pointer;
    }

    .faq-answer-row {
        width: 85%;
        display: flex;
        flex-direction: row;
        justify-content: start;
        align-items: center;
    }

    .faq-title-style {
        color: #000;
        text-align: center;
        font-family: 'Inter', sans-serif;
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
    }

    .faq-answer-style {
        color: rgba(13, 14, 16, 0.70);
        font-family: 'Inter', sans-serif;
        font-size: 13px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
    }

}

.div-width {
    width: 100%;
}

.offer-div-width {
    width: 12.5%;
}

/* Contact Us */
.pricing-contact-us-div {
    width: 100%;
    padding: 100px 0px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 10px;
    background-color: #EDF7F8;
}

.pricing-contact-us-heading {
    color: #082856;
    text-align: center;
    font-family: 'Inter', sans-serif;
    font-size: 36px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    padding-top: 20px;
}

.pricing-contact-us-subheading {
    color: #082856;
    text-align: center;
    font-family: 'Inter', sans-serif;
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    padding-top: 4px;
}

.pricing-contact-us-row {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    /* gap: 50; */
    padding: 50px 0px;
    width: 27.5%;
}

.pricing-contact-us-started {
    display: flex;
    width: 171px;
    padding: 8px 0px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    border-radius: 5px;
    background: #082856;
    color: #FFF;
    text-align: center;
    font-family: 'Inter', sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 30px; /* 187.5% */
    cursor: pointer;
}

.pricing-contact-us-contact {
    display: flex;
    width: 171px;
    padding: 8px 0px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    border-radius: 5px;
    background: #F4F4F4;
    color: #082856;
    text-align: center;
    font-family: 'Inter', sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 30px; /* 187.5% */
    cursor: pointer;
}

@media screen and (max-width: 430px) {

    .pricing-contact-us-div {
        width: 100%;
        padding-top: 70px;
        padding-bottom: 20px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 10px;
        background-color: #EDF7F8;
    }

    .pricing-contact-us-subheading {
        color: #082856;
        text-align: center;
        font-family: 'Inter', sans-serif;
        font-size: 18px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
        padding-top: 4px;
        width: 60%;
    }

    .pricing-contact-us-row {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
        gap: 30px;
        padding: 50px 0px;
        width: 27.5%;
    }

    .offer-div-width {
        width: 40%;
    }

}

@import url('https://fonts.googleapis.com/css2?family=Jost:ital,wght@0,100..900;1,100..900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100..900&display=swap');

html, body {
    overflow-x: hidden;
  }
  body {
    position: relative;
  }

.footer-container {
    display: flex;
    flex-direction: row;
    padding: 100.5px 183px 50px 183px;
    justify-content: start;
    align-items: start;
    background: #FFF;
}

.footer-column {
    display: flex;
    width: 23.5%;
    justify-content: center;
    align-items: start;
    background: #FFF;
    flex-direction: column;
    gap: 14px;
}

.footer-column-heading {
    color: var(--Dark, #0A142F);
    font-family: "Jost", sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 28px; /* 175% */
}  

.footer-column-subheading {
    color: var(--Dark, #0A142F);
    font-family: 'Inter', sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    opacity: 0.5;
    cursor: pointer;
    padding-top: 10px;
}

.newletter-div {
    display: flex;
    padding: 31px 42px;
    flex-direction: column;
    align-items: flex-start;
    gap: 10px;
    background: rgba(32, 60, 134, 0.05);
}

.newletter-heading {
    color: var(--Dark, #0A142F);
    font-family: "Jost", sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 28px;
}

.newsletter-text-field-box {
    display: flex;
    align-items: center;
    width: 248px;
    height: 50px;
    border-radius: 6px;
    border: 1.5px solid #E7E8F2;
  }
  
.newsletter-text-field {
    width: calc(100% - 50px); /* Adjust width according to the width of the row-item */
    height: 100%;
    border: none; /* Remove default border */
    padding: 0 10px; /* Adjust padding as needed */
    outline: none;
    border-radius: 6px;
}
  
.newsletter-text-field-button{
    width: 50px;
    height: 50px;
    background-color: #0081FE;
    border-radius: 0px 6px 6px 0px;
    display: flex;
    justify-content: center;
    align-items: center;
}
 
.newletter-subheading {
    width: 254px;
    height: 82px;
    flex-shrink: 0;
    color: var(--Dark, #0A142F);
    font-family: 'Inter', sans-serif;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 20.4px; 
    opacity: 0.6;
}

.footer-divider {
    width: 80%;
    height: 1px;
    border: 1px solid var(--Dark, #0A142F);
    opacity: 0.06;
    background: var(--Dark, #0A142F);
    margin-left: 10%;
}

.footer-last-row{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
}

.footer-logo {
    width: 230px;
    height: 30px;
    padding-left: 10%;
    padding-top: 20px;
}

.footer-terms-row{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 35px;
    padding-top: 20px;
}

.footer-terms-text {
    color: var(--Dark, #000);
    font-family: 'Inter', sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    cursor: pointer;
}

.footer-social-row{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 15px;
    margin-right: 10%;
    padding-top: 20px;
    cursor: pointer;
}

.mobile-footer-container {
    display: none;
    flex-direction: column;
    padding: 20px 0px 0px 0px;
    justify-content: center;
    align-items: center;
    background: #FFF;
}

.mobile-footer-link-row {
    width: 100%;
    display: none;
    flex-direction: row;
    padding: 0px 0px 0px 80px;
    gap: 35%;
    justify-content: flex-start;
    align-items: flex-start;
    background: #FFF;
}

@media screen and (max-width: 430px){

    .footer-container {
        display: none;
    }

    .mobile-footer-container {
        display: flex;
    }

    .mobile-footer-link-row{
        display: flex;
    }

    .newletter-div {
        display: flex;
        padding: 31px 42px;
        flex-direction: column;
        align-items: flex-start;
        gap: 10px;
        background: rgba(32, 60, 134, 0.05);
        width: 72.5%;
        margin-top: 20px;
    }

    .footer-divider {
        width: 100%;
        height: 1px;
        border: 1px solid var(--Dark, #0A142F);
        opacity: 0.06;
        background: var(--Dark, #0A142F);
        margin-left: 0%;
        margin-top: 54px;
    }


    .footer-last-row{
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
        gap: 30px;
        margin-bottom: 20px;
    }
    
    .footer-logo {
        width: 230px;
        height: 30px;
        padding-left: 0%;
        padding-top: 20px;
    }
    
    .footer-social-row{
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        gap: 25px;
        margin-right: 0%;
        cursor: pointer;
        margin-top: 20px;
    }

}
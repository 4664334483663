@import url("https://fonts.googleapis.com/css2?family=DM+Sans:ital,opsz,wght@0,9..40,100..1000;1,9..40,100..1000&display=swap");

/* Ensure html and body take full height */
html,
body {
  margin: 0;
  padding: 0;
  height: 100%;
}

/* Container for the landing page header */
.new-landing-page-header {
  position: relative;
  width: 100%;
  height: 100vh; /* Full viewport height */
  overflow: hidden;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

/* Fullscreen banner image */
.new-landing-banner-img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  position: absolute;
  top: -70px;
  left: 0;
  background-color: #27c0ef;
  z-index: 1;
}

/* Content stacked over the banner */
.new-landing-header-content {
  position: relative;
  z-index: 2;
  color: white;
  text-align: right;
  height: 50%;
  width: 100%;
}

/* Styling for the div */
.new-landing-header-title {
  width: 625px;
  height: 100px;
  flex-shrink: 0;
  color: #fff;

  font-family: "DM Sans", sans-serif;
  font-size: 44px;
  font-style: normal;
  font-weight: 700;
  line-height: 76px;

  display: flex;
  justify-content: center;
  align-items: center;
  text-align: left;

  padding-bottom: 0;
  padding-left: 140px;
}

.new-landing-header-subtitle {
  width: 600px;
  flex-shrink: 0;
  color: #fff;

  font-family: "DM Sans", sans-serif;
  font-size: 22px;
  font-style: normal;
  font-weight: 400;
  line-height: 26px;

  display: flex;
  justify-content: center;
  align-items: center;
  text-align: left;

  padding-top: 50px;
  padding-left: 140px;
}

.new-landing-book-demo {
  color: #fff;
  font-family: "DM Sans", sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  text-align: left;
  cursor: pointer;
}

.new-landing-info-img {
  width: 550px;
  position: relative;
  bottom: 575px;
  right: 11%;
  z-index: 100;
}

.new-landing-smiley-img {
  width: 175px;
  padding-top: 120px;
  padding-left: 140px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: left;
  z-index: 100;
}

.new-landing-page-header-mobile {
  display: none;
}

.new-landing-book-demo-row {
  width: 600px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  gap: 16px;
  margin-top: 32px;
}

.new-landing-page-hiw {
  padding: 8px 20px;
  flex-shrink: 0;
  background-color: #ffffff;
  color: #00a8db;
  text-align: center;
  font-family: "DM Sans", sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  border: #ffffff 1px solid;
  border-radius: 8px;
  cursor: pointer;
}

@media screen and (max-width: 430px) {
  .new-landing-book-demo-row {
    width: 350px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row;
    gap: 16px;
    margin-top: 32px;
    margin-left: 20px;
  }

  .new-landing-page-header {
    display: none;
  }

  .new-landing-page-header-mobile {
    position: relative;
    width: 100%;
    height: 100vh;
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .new-landing-banner-img {
    width: 100%;
    height: 100%;
    object-fit: fill;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
    background-color: #27c0ef;
  }

  .new-landing-header-content {
    position: relative;
    z-index: 2;
    color: white;
    text-align: right;
    height: 100%;
  }

  .new-landing-header-title {
    width: 350px;
    flex-shrink: 0;
    color: #fff;

    font-family: "DM Sans", sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 25px;

    display: flex;
    justify-content: center;
    align-items: flex-start;
    text-align: left; /* Change to center if you want text centered inside */

    padding-left: 0; /* Reset left padding */
    padding-top: 50px;

    margin: 0 auto; /* Centers the whole container */
  }

  .new-landing-header-subtitle {
    width: 350px;
    flex-shrink: 0;
    color: #fff;

    font-family: "DM Sans", sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 25px;

    display: flex;
    justify-content: center;
    align-items: flex-start;
    text-align: left;
    padding-left: 20px;
    padding-top: 400px;
  }

  .new-landing-book-demo {
    color: #fff;
    font-family: "DM Sans", sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    text-align: left;
  }

  .new-landing-info-img {
    width: 90%;
    position: relative;
    z-index: 100;
    display: block;
    margin: 0 auto;
    left: 43%;
    transform: translateX(-50%);
  }
}

@media screen and (max-width: 375px) {
  .new-landing-page-header-mobile {
    position: relative;
    width: 100%;
    height: 115vh;
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

.new-landing-device-web {
  display: flex;
  flex-direction: column;
}

.new-landing-device-main-mobile {
  display: none;
}

.new-landing-device-row {
  display: flex;
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
  flex-direction: row;
}

.new-landing-device-mobile {
  width: 35%;
}

.new-landing-device-column {
  display: flex;
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

/* Styling for the div */
.new-landing-device-column-heading {
  width: 606px;
  height: 51px;
  flex-shrink: 0;
  color: #034d82;

  font-family: "DM Sans", sans-serif;
  font-size: 36px;
  font-style: normal;
  font-weight: 700;
  line-height: 52px; /* 144.444% */
  letter-spacing: 0.3px;

  display: flex;
  align-items: center; /* Vertically center content */
}

.new-landing-device-column-subheading {
  width: 606px;
  height: 51px;
  flex-shrink: 0;
  color: #90a3b4;

  font-family: "DM Sans", sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 32px; /* 144.444% */

  display: flex;
  align-items: center; /* Vertically center content */
  padding-top: 32px;
  padding-bottom: 72px;
}

.new-landing-device-check-row {
  display: flex;
  align-items: center;
  flex-direction: row;
  padding-top: 8px;
}

.new-landing-device-column-check-subheading {
  width: 606px;
  flex-shrink: 0;
  color: #90a3b4;

  font-family: "DM Sans", sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 32px; /* 144.444% */

  display: flex;
  align-items: center; /* Vertically center content */
}

@media screen and (max-width: 430px) {
  .new-landing-device-web {
    display: none;
  }

  .new-landing-device-main-mobile {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: start;
  }

  .new-landing-device-mobile {
    width: 75%;
  }

  .new-landing-device-column-heading {
    width: 90%;
    height: auto;
    flex-shrink: 0;
    color: #034d82;

    font-family: "DM Sans", sans-serif;
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    line-height: 32px;
    letter-spacing: 0.3px;

    display: flex;
    align-items: flex-start;
    justify-content: start;
    text-align: left;
    padding: 20px 20px 0 20px;
  }

  .new-landing-device-column-subheading {
    width: 90%;
    flex-shrink: 0;
    color: #90a3b4;

    font-family: "DM Sans", sans-serif;
    font-size: 10px;
    font-style: normal;
    font-weight: 400;
    line-height: 25px;

    display: flex;
    align-items: flex-start;
    justify-content: start;
    text-align: left;
    padding: 0 20px 12px 20px;
  }

  .new-landing-device-column-check-subheading {
    width: 95%;
    flex-shrink: 0;
    color: #90a3b4;

    font-family: "DM Sans", sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 32px; /* 144.444% */

    display: flex;
    align-items: center; /* Vertically center content */
    padding-left: 8px;
  }
}

/* Styling for the div */
.new-landing-know-more {
  margin-top: 100px;
  width: 100%;
  height: 675px;
  flex-shrink: 0;
  background-color: #effbff;
  /*align-items: center;*/
  justify-content: center;
  display: flex;
  flex-direction: column;
}

.new-landing-know-more-row {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: row;
  padding: 24px 12px 0 12px;
}

/* Styling for the div */
.new-landing-know-more-card {
  width: 260px;
  height: 450px;
  flex-shrink: 0;
  border-radius: 8px;
  background-color: #fff;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  padding: 26px 20px 0 20px;
}

.new-landing-know-more-img {
  width: 70px;
  height: 70px;
}

.new-landing-know-more-heading {
  color: #034d82;
  font-family: "DM Sans", sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 28px; /* 175% */
  letter-spacing: 0.2px;
  padding-top: 32px;
}

/* Styling for the div */
.new-landing-know-more-subheading {
  color: #8ea9c1;
  font-family: "DM Sans", sans-serif;
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px; /* 160% */
  letter-spacing: 0.3px;
  padding-top: 32px;
}

.new-landing-know-more-main-subheading {
  color: #8ea9c1;
  font-family: "DM Sans", sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px; /* 160% */
  letter-spacing: 0.3px;
  padding-top: 32px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.new-landing-know-more-main-heading {
  width: 100%;
  flex-shrink: 0;
  color: #034d82;
  font-family: "DM Sans", sans-serif;
  font-size: 36px;
  font-style: normal;
  font-weight: 700;
  line-height: 52px; /* 144.444% */
  letter-spacing: 0.3px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

@media screen and (max-width: 430px) {
  .new-landing-know-more {
    margin-top: 50px;
    width: 100%;
    height: 100%;
    flex-shrink: 0;
    background-color: #effbff;
    /*align-items: center;*/
    justify-content: center;
    display: flex;
    flex-direction: column;
  }

  /*.new-landing-know-more-row {*/
  /*    width: 100%;*/
  /*    display: flex;*/
  /*    align-items: center;*/
  /*    justify-content: space-between;*/
  /*    flex-direction: row;*/
  /*    padding: 24px 12px 0 12px;*/
  /*}*/
  .new-landing-know-more-main-heading {
    width: 350px;
    flex-shrink: 0;
    color: #034d82;
    font-family: "DM Sans", sans-serif;
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    line-height: 32px; /* 144.444% */
    letter-spacing: 0.3px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding-top: 20px;
    padding-left: 20px;
  }

  .new-landing-know-more-main-subheading {
    color: #8ea9c1;
    font-family: "DM Sans", sans-serif;
    font-size: 10px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px; /* 160% */
    letter-spacing: 0.3px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    text-align: center;
    padding: 16px 65px 0 65px;
  }

  .new-landing-know-more-row {
    display: flex;
    flex-direction: row;
    overflow-x: auto; /* Enable horizontal scrolling */
    scroll-snap-type: x mandatory; /* Optional: for smooth scroll snapping */
    gap: 12px;
    padding-bottom: 24px;
    margin: 12px 32px 0 32px;
  }

  .new-landing-know-more-card {
    border-radius: 8px;
    background-color: #fff;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    padding: 26px 20px 0 20px;
    flex: 0 0 40%;
    width: 30%;
    height: 350px;
    scroll-snap-align: start;
  }

  .new-landing-know-more-img {
    width: 25px;
    height: 25px; /* Adjust image size for mobile */
  }

  .new-landing-know-more-heading {
    font-size: 11px; /* Adjust font size for mobile */
    padding-top: 20px; /* Adjust padding for mobile */
    line-height: 15px;
  }

  .new-landing-know-more-subheading {
    font-size: 10px; /* Adjust font size for mobile */
    line-height: 20px; /* Adjust line height */
    padding-top: 16px; /* Adjust padding for mobile */
  }
}

/* Styling for the gradient div */
.new-landing-connect-now-row {
  width: 100%;
  height: 80%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-content: center;
}

.new-landing-connect-gradient {
  margin-top: 56px;
  width: 1100px;
  height: 135px;
  flex-shrink: 0;
  border-radius: 8px;
  background: linear-gradient(270deg, #9cc8e3 0.05%, #015aa3 97.84%);
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding-left: 92px;
  padding-right: 92px;
}

.new-landing-connect-heading {
  color: #fff;
  font-family: "DM Sans", sans-serif;
  font-size: 36px;
  font-style: normal;
  font-weight: 700;
  line-height: 44px; /* 122.222% */
  letter-spacing: 0.3px;
  text-decoration-line: underline;
}

/* Styling for the flex container div */
.new-landing-connect-button {
  display: flex;
  width: 245px;
  height: 56px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  flex-shrink: 0;
  border-radius: 5px;
  background-color: #082856;
  color: #fff;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  cursor: pointer;
}

.new-landing-connect-now-check-row {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-content: center;
  padding-top: 16px;
}

.new-landing-connect-now-check {
  width: 750px;
  color: #90a3b4;
  font-family: "DM Sans", sans-serif;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 32px; /* 144.444% */
  display: flex;
}

.new-landing-connect-now-row-mobile {
  width: 100%;
  display: none;
  flex-direction: row;
  justify-content: center;
  align-content: center;
}

.new-landing-whatsapp-button {
  display: flex;
  width: 200px;
  height: 30px;
  padding: 8px 20px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  flex-shrink: 0;
  border-radius: 5px;
  background-color: #23b75a;
  color: #fff;
  font-family: "DM Sans", sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  cursor: pointer;
  line-height: normal;
}

.new-landing-mob-height {
  height: 24px;
}

@media screen and (max-width: 430px) {
  .new-landing-connect-now-row {
    width: 80%;
    height: 80%;
    display: none;
    flex-direction: row;
    justify-content: center;
    align-content: center;
  }

  .new-landing-connect-now-row-mobile {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-content: center;
  }

  .new-landing-connect-gradient-mobile {
    display: flex;
    flex-direction: row;
    width: 80%;
    height: 37px;
    flex-shrink: 0;
    border-radius: 8px;
    background: linear-gradient(270deg, #9cc8e3 0.05%, #015aa3 97.84%);
    justify-content: space-between;
    align-items: center;
    margin-top: 32px;
    padding-left: 16px;
    padding-right: 16px;
  }

  .new-landing-connect-gradient {
    margin-top: 56px;
    width: 100%;
    height: 40px;
    flex-shrink: 0;
    border-radius: 8px;
    background: linear-gradient(270deg, #9cc8e3 0.05%, #015aa3 97.84%);
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding-left: 92px;
    padding-right: 92px;
  }

  .new-landing-connect-heading {
    color: #fff;
    font-family: "DM Sans", sans-serif;
    font-size: 12px;
    font-style: normal;
    font-weight: 700;
    line-height: 22px; /* 122.222% */
    letter-spacing: 0.3px;
    text-decoration-line: underline;
  }

  /* Styling for the flex container div */
  .new-landing-connect-button {
    display: flex;
    width: 100px;
    height: 18px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    flex-shrink: 0;
    border-radius: 5px;
    background-color: #082856;
    color: #fff;
    font-size: 10px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
  }

  .new-landing-connect-now-check-row {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-content: center;
    padding-top: 16px;
  }

  .new-landing-connect-now-check {
    width: 750px;
    color: #90a3b4;
    font-family: "DM Sans", sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px; /* 144.444% */
    display: flex;
    padding-top: 8px;
  }

  .new-landing-whatsapp-button {
    display: flex;
    width: 125px;
    height: 20px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    flex-shrink: 0;
    border-radius: 5px;
    background-color: #23b75a;
    color: #fff;
    font-family: "DM Sans", sans-serif;
    font-size: 12px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    margin-left: 24px;
  }

  .new-landing-mob-height {
    height: 200px;
  }
}

.new-landing-header-pop-up {
  width: 525px;
  padding: 0 0;
  flex-shrink: 0;
  border-radius: 45px;
  background: #f8fff7;

  position: absolute;
  top: 50%;
  left: 32.5%;
  transform: translate(-50%, -50%);
  z-index: 1000;
}

.new-landing-header-pop-up-title-row {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  padding-right: 24px;
  gap: 20%;
}

.new-landing-header-pop-up-close-btn {
  cursor: pointer;
}

.new-landing-header-pop-up-content-column {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 16px;
  padding-top: 16px;
}

.new-landing-header-pop-up-heading {
  color: #082856;
  font-family: "Inter", sans-serif;
  font-size: 30px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-align: center;
}

.new-landing-header-pop-up-subheading {
  width: 400px;
  color: #015aa3;
  font-family: "Inter", sans-serif;
  font-size: 19px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.new-landing-header-pop-up-date {
  width: 300px;
  color: #082856;
  font-family: "DM Sans", sans-serif;
  font-size: 18px;
  font-style: italic;
  font-weight: 400;
  line-height: 26px;
  text-decoration-line: underline;
}

.new-landing-header-pop-up-online {
  color: #082856;
  font-family: "Inter", sans-serif; /* Ensure the font is loaded */
  font-size: 18px;
  font-style: normal;
  font-weight: 700; /* Bold text */
  line-height: normal; /* Default line height */
}

.new-landing-header-pop-up-linkedin {
  width: 60%;
  height: 100%;
  flex-shrink: 0;
  border-radius: 45px;
  background: #f8fff7;
  position: absolute;
  top: 30%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 1000;
}

.new-landing-pop-up-image-wrapper {
  position: relative;
  width: 100%;
  border-radius: 45px;
  overflow: hidden;
}

.new-landing-pop-up-image {
  width: 101%;
  border-radius: 30px;
}

.new-landing-header-pop-up-close-btn {
  position: absolute;
  top: 10px;
  right: 20px;
  cursor: pointer;
  z-index: 10;
}

@media screen and (max-width: 430px) {
  .new-landing-header-pop-up {
    width: 95%;
    padding: 0 0;
    flex-shrink: 0;
    border-radius: 45px;
    background: #f8fff7;

    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 1000;
  }

  .new-landing-header-pop-up-heading {
    color: #082856;
    font-family: "Inter", sans-serif;
    font-size: 25px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }

  .new-landing-header-pop-up-subheading {
    width: 90%;
    color: #015aa3;
    font-family: "Inter", sans-serif;
    font-size: 19px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    text-align: left;
  }

  .new-landing-header-pop-up-date {
    width: 300px;
    color: #082856;
    font-family: "DM Sans", sans-serif;
    font-size: 18px;
    font-style: italic;
    font-weight: 400;
    line-height: 26px;
    text-decoration-line: underline;
  }

  .new-landing-header-pop-up-online {
    color: #082856;
    font-family: "Inter", sans-serif; /* Ensure the font is loaded */
    font-size: 16px;
    font-style: normal;
    font-weight: 700; /* Bold text */
    line-height: normal; /* Default line height */
  }

  .new-landing-header-pop-up-linkedin {
    width: 80%;
    height: auto;
    flex-shrink: 0;
    border-radius: 25px;
    background: #f8fff7;
    position: absolute;
    top: 40%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 1000;
  }

  .new-landing-pop-up-image-wrapper {
    position: relative;
    width: 100%;
    border-radius: 15px;
    overflow: hidden;
  }

  .new-landing-pop-up-image {
    width: 100%;
    border-radius: 15px;
  }

  .new-landing-header-pop-up-close-btn {
    position: absolute;
    top: 10px;
    right: 20px;
    cursor: pointer;
    z-index: 10;
  }
}

.image-overlay-container {
  position: relative;
  display: inline-block;
}

.overlay-button {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 25%;
  background-color: transparent;
  border: none;
  cursor: pointer;
}
